document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-side-menu-toggle]").forEach((element) => {
    element.addEventListener("htmx:beforeSend", (event) => {
      if (document.body.classList.contains("page--collapsed-side-menu")) {
        event.detail.requestConfig.parameters["expand"] = true;
      } else {
        event.detail.requestConfig.parameters["collapse"] = true;
      }
    });
  });
});
